import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ConfigProvider } from 'antd';
import frFR from 'antd/lib/locale/fr_FR';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import store from './redux/store';


import './assets/styles/App.less';
import './index.css';
import './i18n';


import './helpers/array-prototype';

import App from './App';
import reportWebVitals from './reportWebVitals';

const stringText= `   _|_|_|                        _|      _|          _|      _|                                                              
_|          _|_|_|  _|_|_|    _|_|_|_|_|_|_|_|      _|_|  _|_|    _|_|_|  _|_|_|      _|_|_|    _|_|_|    _|_|    _|  _|_|  
_|  _|_|  _|    _|  _|    _|    _|      _|          _|  _|  _|  _|    _|  _|    _|  _|    _|  _|    _|  _|_|_|_|  _|_|      
_|    _|  _|    _|  _|    _|    _|      _|          _|      _|  _|    _|  _|    _|  _|    _|  _|    _|  _|        _|        
  _|_|_|    _|_|_|  _|    _|      _|_|    _|_|      _|      _|    _|_|_|  _|    _|    _|_|_|    _|_|_|    _|_|_|  _|        
                                                                                                    _|                      
                                                                                                _|_|      `;
/* eslint-disable-next-line */
console.log('%c' + stringText, 'color: yellow')
if (process.env.REACT_APP_STAGE === 'production') {
Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
    integrations: [new Integrations.BrowserTracing()],

    // Set traceSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});
console.log()
    window.console.log = () => {};
} 
if (process.env.REACT_APP_STAGE === 'staging'){
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN_STAGING,
        environment: process.env.REACT_APP_STAGE,
        integrations: [new Integrations.BrowserTracing()],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
      });
}
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <ConfigProvider locale={frFR}>
            <App />
        </ConfigProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
